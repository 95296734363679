import React from "react";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { Box, Button, IconButton, Collapse } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import MuiLink from "@material-ui/core/Link";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import HorizontalLogo from "../images/v2/campaigns/Horizontal-logo.png";
import callImg from "../images/v2/campaigns/call.svg";
import Divider from "@material-ui/core/Divider";
import singaporeSmeBannerImg from "../images/v2/whatsapp/backgroundImg1.png";
import tickImage from "../images/v2/whatsapp/tickIconImage.png";
import useWidth from "../hooks/useWidth";
import requestDemoBGImg from "../images/v2/whatsapp/crmBackgroundImg.png";
import loadable from "@loadable/component";
import Hidden from "@material-ui/core/Hidden";
import WhiteButton from "../components/v2/WhightButton";
import IntegratedCRMBgImg from "../images/v2/home/integrated-crm-bg.svg";
import singaporeSmeTestimonialImg from "../images/v2/whatsapp/bannerImg.png";
import faqbackgroundImg from "../images/v2/whatsapp/faqbackground.png";
import { conversionEventOnCall } from "./singapore-sme-v2";
import SEO from "../components/SEO";
import whatsappBackgroundImg from "../images/v2/campaigns/whatsappBackgroundImg.png";
import whatsappTitleImage from "../images/v2/campaigns/whatsappTitleImage.png";
import yellowLine from "../images/v2/whatsapp/blackLine.png";
import clientLogo1 from "../images/v2/whatsapp/listIcon1.png";
import clientLogo2 from "../images/v2/whatsapp/homeImage.png";
import clientLogo3 from "../images/v2/whatsapp/modalImage.png";
import clientLogo4 from "../images/v2/whatsapp/soundImage.png";
import clientLogo5 from "../images/v2/whatsapp/recievemsgImage.png";
import clientLogo6 from "../images/v2/whatsapp/messageImage.png";
import logo1 from "../images/v2/whatsapp/whatsapp.png";
import logo2 from "../images/v2/whatsapp/meta.png";
import logo3 from "../images/v2/whatsapp/iim.png";
import logo4 from "../images/v2/whatsapp/sg.png";
import logo5 from "../images/v2/whatsapp/approved.png";
import { StaticImage } from "gatsby-plugin-image";
import firstImage from "../images/v2/whatsapp/FirstImageGreen.png";
import secondImage from "../images/v2/whatsapp/SecondImageBlue.png";
import Logo from "../images/v2/PC-vertical-logo.svg";
import clientLogo100 from "../images/v2/whatsappCardImage/manufacturing.png";
import clientLogo200 from "../images/v2/whatsappCardImage/Health Care.png";
import clientLogo300 from "../images/v2/whatsappCardImage/Food & Delivery.png";
import card1Img from "../images/v2/whatsapp/tech.png";
import card2Img from "../images/v2/whatsapp/doctor.png";
import card3Img from "../images/v2/whatsapp/delivery.png";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import whatsapptextImg1 from "../images/v2/whatsapp/text12x.png";
import whatsapptextImg2 from "../images/v2/whatsapp/text22x.png";
import whatsapptextImg3 from "../images/v2/whatsapp/text32x.png";
import IMDA_SME_Large from "../images/v2/psg/IMDA_SME_Large.webp";
import IMDA_SME_Medium from "../images/v2/psg/IMDA_SME_Medium.webp";
import { BorderBottom } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  SmeStyling: {
    width: "74%",
    margin: "20px auto 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px auto 0",
    },
  },
  wpHeader: {
    paddingBottom: "20px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  marginAuto: {
    margin: "0 auto",
  },
  header: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.29)",
    padding: ".65rem 0 .25rem",
  },
  backgroundWhite: {
    position: `relative`,
    bacgroundColor: `#fff`,
  },

  cardImg: {
    width: "100%",
    height: "auto",
    transform: "scale(1.1)",
  },

  smeHeroImg: {
    marginBottom: 0,
    marginLeft: "53px",
    maxWidth: "450px",
    width: "100%",
    padding: 0,
    background: "white",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },

  relativeContainer2: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
  },

  cardindexBackground: {
    boxShadow: "none",
    borderRadius: "12px",
    border: "solid 1px #0893af",
  },
  faqbackground: {
    position: `relative`,
    background: `#fff url(${faqbackgroundImg}) no-repeat center`,
    backgroundSize: "contain",
    margin: "79.5px 0 ",
    [theme.breakpoints.down("xs")]: {
      marginTop: "28px",
      marginBottom: "50px",
    },
  },
  whatsappBackground: {
    position: "relative",
    background: `#fff url(${whatsappBackgroundImg}) no-repeat center`,
    width: "100%",
    backgroundSize: "cover",
  },
  singaporeSmeBanner: {
    position: `relative`,
    background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
    [theme.breakpoints.up(1600)]: {
      position: `relative`,
      background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
      backgroundSize: "cover",
      width: "100%",
    },
  },
  primaryText: {
    fontSize: "20px",
    fontWeight: "600",
    fontFamily: "Poppins",
    paddingBottom: "4px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
    },
  },
  secondaryText: {
    fontSize: "20px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
    },
  },

  listBackground: {
    position: `relative`,
    // background: `#fff url(${listBackgroundImage}) no-repeat center`,
    // backgroundSize: "cover",
    width: "100%",
  },
  singaporeSmeBannerRightImg: {
    position: `absolute`,
    backgroundSize: "contain",
    paddingTop: "50px",
    right: `-25%`,
    backgroundPosition: `right`,
    width: `100%`,
    height: `100%`,
    top: `25%`,
    [theme.breakpoints.down(1120)]: {
      display: `none`,
    },
    [theme.breakpoints.up(1300)]: {
      right: `-20%`,
    },
    [theme.breakpoints.up(1600)]: {
      right: `-15%`,
    },
    [theme.breakpoints.up(1900)]: {
      right: `0%`,
    },
  },
  singaporeSmeFromBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      paddingTop: "0",
    },
    [theme.breakpoints.up("xs")]: {
      paddingTop: "5rem",
    },
  },

  listItemTextes: {
    "& *": {
      fontSize: 23,
      color: "#2e3f4f",
      whiteSpace: "pre",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemTexts: {
    "& *": {
      fontSize: 20,
      color: "#2e3f4f",
      whiteSpace: "pre",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        whiteSpace: "pre",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        whiteSpace: "normal",
      },
    },
  },
  listItemTextWhite: {
    "& *": {
      fontSize: 20,
      color: "#fff",
      whiteSpace: "noWrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 15,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
      },
    },
  },
  tickImages: {
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      padding: "0",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      padding: "0",
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 26,
    backgroundColor: "#15a323",
    borderRadius: "50%",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  singaporeSmeFrom: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 #b7b7b7",
    backgroundSize: "430px 522px",
    position: "relative",
    zIndex: "1",
    margin: "0 auto",
    width: "90%",
    marginLeft: "56px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
  },
  singaporeSmeFromHeader: {
    background: "#2e3f4f",
    borderRadius: "10px 10px 0 0",
    padding: ".6rem .25rem",
    letterSpacing: ".32px",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  integration: {
    whiteSpace: "nowrap",
  },

  img: {
    backgroundSize: "contain",
  },
  nopad: {
    paddingLeft: "0",
    paddingTop: "0",
  },
  nopads: {
    paddingBottom: "24px",
  },

  nopadtpbtm: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listPadding: {
    whiteSpace: "pre",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
  listFourpadding: {
    paddingLeft: "56px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  colorChange: {
    color: "#F0B616",
    fontSize: "30px",
    fontWeight: "700",
    letterSpacing: ".4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  whitespaceNowrap: {
    whiteSpace: "nowrap",
  },
  pepperCloudCRMHelps: {
    fontSize: "40px",
    [theme.breakpoints.up("md")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
      paddingTop: "32px",
      fontSize: "40px",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  pepperCloudCRMHelpsText: {
    fontSize: "40px",
    [theme.breakpoints.up("md")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
      paddingTop: "4px",
      fontSize: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
      paddingTop: "4px",
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
      paddingTop: "4px",
      fontSize: "22px",
    },
  },
  pepperCloudCRMHelpsContent: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#fff7e0",
    padding: "11px 0 20px 0",
  },
  pepperCloudCRMHelpsContentListBox: {
    padding: "0rem 1rem",
  },
  pepperCloudCRMHelpsContentList: {
    listStyle: "none",
    padding: "0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  footerPadding: {
    paddingTop: "60px",
    paddingBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
  },
  pepperCloudCRMHelpsContentListItem: {
    display: "inline-block",
    paddingRight: "2rem",
    marginRight: "2rem",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none !important",
    },
  },
  copyFooter: {
    color: "#cbcccd",
    textAlign: "end",
    paddingRight: "23px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  yellowImageLine: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  pepperCloudCRMHelpsContentListItemSpan: {
    color: "#e2ab10",
    fontSize: "61px",
    fontWeight: "600",
    lineHeight: "normal",
    fontFamily: "Poppins",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  faqCard: {
    borderRadius: "20px",
    boxShadow: "1px 1px 10px 0 rgba(0, 0, 0, 0.16)",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  smeBannerTxts: {
    padding: "3px",
    margin: "0",
    marginLeft: "5px",
    paddingBottom: "24px",
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-18px",
      lineHeight: "30px",
      paddingBottom: "24px",
      marginLeft: "auto",
    },
  },
  whatsappDisclaimer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "24px",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      paddingRight: "0",
    },
  },
  cimg: {
    padding: "0 15px",
  },

  toolPadding: {
    paddingBottom: "24px",
  },
  logoImage1: {
    display: "flex",
    columnGap: "52px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "contents",
      columnGap: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "contents",
      columnGap: "0px",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-around",
      columnGap: "0px",
    },
  },
  containerImage: {
    marginTop: "79px",
    [theme.breakpoints.down("md")]: {
      marginTop: "39px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "39px",
    },
  },
  whatsappTitleImageContainer: {
    position: "absolute",
    top: "18px",
    paddingLeft: "6rem",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "6rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  listTopPadding: {
    paddingTop: "32px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  gridContainer: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  relativeContainerSide: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "50px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "50px",
      margin: "auto",
    },
  },
  cardindexBackground: {
    boxShadow: "none",
    borderRadius: "12px",
    border: "solid 1px #0893af",
  },
  bannerImageClass: {
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
  },
  boostTextwidth: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up(1120)]: {
      width: "1120px",
    },
  },
  listColumn: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexFlow: "column-reverse",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexFlow: "column-reverse",
    },
  },
  headingwidth: {
    maxWidth: "800px",
    paddingTop: "32px",
    paddingBottom: "18px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  cardindexBackground: {
    boxShadow: "none",
    borderRadius: "12px",
    border: "solid 1px #0893af",
  },

  cardindexBackground1: {
    objectFit: "contain",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 2px #0379895e",
  },
  cardindexBackground2: {
    objectFit: "contain",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 2px #00bb4487",
  },
  textImage: {
    marginBottom: "11px",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      textAlign: "left",
    },
  },
  cardindexBackground3: {
    objectFit: "contain",
    borderRadius: "12px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 2px #df9e037d",
  },
  whyText: {
    textAlignLast: "start",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-60px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-60px",
    },
  },
  smartCrmToolPadding: {
    textAlign: "center",
    position: `relative`,
    background: `#fff url(${firstImage}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
  },
  smartCrmTool: {
    position: `relative`,
    background: `#fff url(${secondImage}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
  },
  pcWebForm: {
    width: "100%",
    marginTop: "-7px",
    height: "496px",
    [theme.breakpoints.down("sm")]: {
      height: "529px",
    },
  },
  webformContainer: {
    height: "490px",
    [theme.breakpoints.down("sm")]: {
      height: "535px",
    },
  },
  demobtn: {
    paddingTop: "40px",
  },
  gap: {
    paddingTop: "70px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0px",
    },
  },
  whatsapptextImg1: {
    position: "absolute",
    right: "-19px",
    width: "360.7px",
    bottom: "-35px",
    [theme.breakpoints.down("md")]: {
      bottom: "-35px,",
      right: "0px",
      width: "100%",
      position: "absolute",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "-35px,",
      right: "0px",
      width: "100%",
      position: "absolute",
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      bottom: "-48px,",
      right: "-9px",
      width: "100%",
    },
  },
  nopadding: {
    display: "block",
    // marginRight: "-76px",

    [theme.breakpoints.up(1200)]: {
      display: "flex",
      marginRight: "-76px",
      paddingLeft: "40px",
    },
  },
  overlapImage: {
    position: "relative",
    zIndex: "1000",
    width: "211.2px",
    height: "102.9px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      zIndex: "0",
      height: "80.9px",
    },
    [theme.breakpoints.down("md")]: {
      width: "171.2px",
      height: "95.9px",
    },
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      zIndex: "0",
      height: "82.9px",
    },
  },
  overlapBox: {
    justifyContent: "space-around",
    display: "flex",
    padding: "0 31px",
    [theme.breakpoints.down("sm")]: {
      display: "contents",
      padding: "0px",
    },
  },
  boostText: {
    paddingBottom: "21px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  overlapBackground: {
    borderRadius: "10px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#fff",
    zIndex: "1000",
    position: "relative",
    height: "145px",
    // marginTop: "-53px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      marginTop: "30px",
      paddingBottom: "14px",
    },
  },
  overlapBackgrounds: {
    borderRadius: "10px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#fff",
    zIndex: "1000",
    position: "relative",
    height: "145px",
    marginTop: "-53px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      marginTop: "-30px",
      paddingBottom: "14px",
    },
  },
  listMargin: {
    marginLeft: "-17px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px",
    },
  },
  headingText: {
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },

  listImage: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
      textAlign: "center",
      flex: "0 0 calc(50% - 20px)",
    },
  },

  relativeContainer: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },

  reactangelImageContainer1: {
    position: "absolute",
    right: "-18px",
    top: "99px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  relativeContainer2: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
  },
  powerUpCardInner1: {
    backgroundColor: "#dbf1e5",
    padding: "14px 20px",
    minHeight: "150px",
    borderRadius: "8px",
  },
  powerUpCardInner2: {
    backgroundColor: "#fff6e3",
    padding: "14px 20px",
    minHeight: "150px",
    borderRadius: "8px",
  },
  powerUpCardInner3: {
    backgroundColor: "#eaf6ff",
    padding: "14px 20px",
    minHeight: "150px",
    borderRadius: "8px",
  },
  smeBannerImg: {
    backgroundColor: "white",
    borderRadius: "12px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
}));

const listOne = `Share catalogs with ease
Send bulk promotions
Chat widget on website`.split(`
`);

const listTwo = `Auto-reply with message templates
Single number for team
Assist customers with AI chatbot`.split(`
`);

const listThree = `Facebook integration
Instagram integration
Telegram integration
Sales management
Sales forecasting`.split(`
`);

const listFour = `Lead  management
Workflow automation
Audio and video calls
Customised dashboard
Integrated with various tools`.split(`
`);

const listFive = `Up to 50% PSG grant
24/7 Local support
No hidden charges
Built for Singapore SMEs`.split(`
`);

const whatsappCrmList = [
  {
    title: "Capture and convert leads automatically",
    name: "Capture leads from incoming WhatsApp chats, and transform every conversation into a sales win.",
    logo: clientLogo1,
    alt: "Lead Capture",
  },
  {
    title: "Sell more with WhatsApp catalog",
    name: "Promote products and services effortlessly through catalog. Enable customers to make instant purchases.",
    logo: clientLogo2,
    alt: "WhatsApp Product Catalog",
  },
  {
    title: "Save manual hours with WhatsApp automation",
    name: "Automate repetitive tasks. Set up automatic alerts, reminders and auto-responses to customer messages.",
    logo: clientLogo3,
    alt: "Workflow Automation",
  },
  {
    title: "Amplify reach with WhatsApp broadcast",
    name: "Leverage the power of WhatsApp marketing and promote your product and services with bulk message broadcast.",
    logo: clientLogo4,
    alt: "Broadcast Messages",
  },
  {
    title: "Assist customers with AI chatbots",
    name: "Enhance customer interactions with AI-driven WhatsApp chatbots. Automate responses to customer queries.",
    logo: clientLogo5,
    alt: "AI Chatbot",
  },
  {
    title: "Send quick replies with message templates",
    name: "Instantly address FAQs and enhance customer engagement with media-rich message templates.",
    logo: clientLogo6,
    alt: "Message Templates",
  },
];

const overlapImageList = [
  {
    logo: logo1,
    alt: "WhatsApp Business Partner",
  },
  {
    logo: logo2,
    alt: "Meta Business Partner",
  },
  {
    logo: logo5,
    alt: "Pre Approved PSG Grant",
  },
];

const clients = [
  {
    name: "Cuscapi Singapore Pte. Ltd. - Pepper Cloud customer",
    logo: clientLogo100,
    alt: "WhatsApp CRM for Retail and eCommerce Business",
    height: 200,
  },
  {
    name: "Centri Core Pte Ltd - Pepper Cloud customer",
    logo: clientLogo200,
    alt: "WhatsApp CRM for Manufacturing Business",
    height: 200,
  },
  {
    name: "Tech Data - Pepper Cloud customer",
    logo: clientLogo300,
    alt: "WhatsApp CRM for Finance Business",
    height: 200,
  },
];
const CompanyCarousel = loadable(() =>
  import("../components/v2/CompanyCarousel")
);

const WhatsappCarousel = loadable(() =>
  import("../components/v2/WhatsappCarousel")
);
const whatsapp = () => {
  const myRef = React.useRef(null);

  const executeScroll = () => {
    myRef.current.scrollIntoView();
  };

  const classes = useStyles();

  const width = useWidth();
  const [activeCalendar, setActiveCalendar] = React.useState(0);
  const salesCRMSolution = [
    {
      title: "What is WhatsApp CRM?",
      description: (
        <>
          WhatsApp CRM is a powerful tool that combines the capabilities of
          WhatsApp with a CRM system. <br /> <br />
          With Pepper Cloud, a WhatsApp CRM tool, you can efficiently manage
          leads, send instant responses using message templates, schedule
          broadcasts, deliver interactive messages, broadcast marketing
          campaigns and do much more.
        </>
      ),
    },
    {
      title: "I’m an SME. How can WhatsApp CRM benefit us?",
      description:
        "CRM system integrated with WhatsApp can be an effective sales tool for SMEs. You can provide personalised customer service, streamline your sales process, and track all customer interactions in one place. With features such as message templates, automated replies, chatbots, tags, etc. you can save time and focus on growing your business.",
    },
    {
      title: "Can I use my existing number for WhatsApp CRM?",
      description:
        "Yes, you can use your existing number for WhatsApp in Pepper Cloud CRM. Additionally, you can use your landline number for WhatsApp to integrate with CRM.",
    },
    {
      title:
        "Can all my team members communicate with customers through one WhatsApp number?",
      description:
        "Yes, the CRM enables your entire team to communicate from a single WhatsApp number.",
    },
    {
      title: "How long does it take to set up WhatsApp for my business?",
      description: (
        <>
          If you have all the prerequisites ready, you can start using WhatsApp
          through CRM system within a few hours.
        </>
      ),
    },
    {
      title:
        "Does Pepper Cloud charge for WhatsApp messages sent through the CRM?",
      description: (
        <>
          No, Pepper Cloud doesn’t charge for the messages. However, you may
          incur charges from WhatsApp. For more details, check out{" "}
          <a
            href="https://peppercloud.com/crm-platfrom-pricing"
            style={{ color: "#198cb5" }}
          >
            WhatsApp pricing.
          </a>
        </>
      ),
    },
    {
      title:
        "What other channel integrations are available in the Pepper Cloud CRM system?",
      description: (
        <>
          Pepper Cloud CRM is a multi-channel integrated platform that
          integrates with WhatsApp, Facebook Messenger, Instagram, Telegram, and
          others.
        </>
      ),
    },
    {
      title: "Can I use the PSG grant to avail WhatsApp CRM?",
      description: (
        <>
          Pepper Cloud, a WhatsApp CRM, is an IMDA-approved digital solution and
          you can use your PSG grant to avail it. To learn more about the PSG
          application process, check out an{" "}
          <a
            href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant"
            style={{ color: "#198cb5" }}
          >
            ultimate guide to Productivity Solutions Grant(PSG).
          </a>
        </>
      ),
    },
    {
      title: "How to get started with WhatsApp CRM?",
      description: (
        <>
          To get started,{" "}
          <a onClick={executeScroll} style={{ color: "#198cb5" }}>
            book a free demo
          </a>{" "}
          with Pepper Cloud CRM.
        </>
      ),
    },
    {
      title: "How many WhatsApp accounts can I integrate?",
      description: (
        <>
          You can integrate unlimited WhatsApp accounts with Pepper Cloud CRM.
        </>
      ),
    },
    {
      title:
        "Is it difficult to use the CRM? Does my team require any prior technical knowledge?",
      description: (
        <>
          Pepper Cloud is an easy-to-use CRM for any growing business and it
          doesn’t need any prior technical knowledge. However, Pepper Cloud
          offer training session to all our clients and provides local support
          24/7.
        </>
      ),
    },
  ];

  React.useEffect(() => {
    const pcIframe = document.getElementById("pc-iframe");
    pcIframe.src = pcIframe.src + window.location.search;
    if (window.location.search)
      pcIframe.src = pcIframe.src + "&page_title=" + document.title;
    else pcIframe.src = pcIframe.src + "?page_title=" + document.title;
  }, []);

  return (
    <React.Fragment>
      <SEO
        canonical="/crm-with-whatsapp-integration"
        description="Pepper Cloud CRM software with WhatsApp Integration to maximise your sales productivity. Manage your entire team's communications in one simple and unified platform."
        keywords="CRM with WhatsApp Integration,whatsapp crm, crm whatsapp integration, whatsapp integration, WhatsApp-enabled CRM"
        pathname="/crm-with-whatsapp-integration"
        title="All-in-one CRM with WhatsApp Integration | Pepper Cloud"
      />
      <Box className={classes.header}>
        <Box component={Container}>
          <Grid alignItems="center" container justifyContent="center">
            <Grid item md={7} sm={12}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="flex-start"
                my={1}
              >
                <Box alignItems="center" display="flex">
                  <Link to="/">
                    <Box
                      alt="Pepper Cloud CRM"
                      component={"img"}
                      height={{ sm: 36, xs: 36 }[width] || 58}
                      mr={{ sm: 1, xs: 1 }[width] || 2}
                      src={HorizontalLogo}
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} sm={12}>
              <Box
                alignItems="flex-end"
                display="flex"
                justifyContent="flex-end"
                my={1}
                textAlign="right"
              >
                <HeaderTypography
                  className="header"
                  color="#2e3f4f"
                  fontSize={{ sm: 13, xs: 13 }[width] || 18}
                  fontWeight={600}
                  m={0}
                  mr={{ sm: 2, xs: 1 }[width] || 2}
                  overrideClassName
                  component="h4"
                >
                  Book a FREE Demo
                </HeaderTypography>
                <MuiLink href="tel:+6597510475" onClick={conversionEventOnCall}>
                  <Box alignItems="center" display="flex">
                    <Box
                      alt="Call to Pepper Cloud"
                      component={"img"}
                      height={{ sm: 14, xs: 14 }[width] || 20}
                      mr={{ sm: 0, xs: 0 }[width] || 1}
                      src={callImg}
                    />
                    <ParagraphTypography
                      className={classes.whitespaceNowrap}
                      color="#0291ae"
                      fontSize={{ sm: 14, xs: 14 }[width] || 20}
                      fontWeight={700}
                      m={0}
                      overrideClassName
                    >
                      +65 97510475
                    </ParagraphTypography>
                  </Box>
                </MuiLink>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Banner */}
      <Box className={classes.singaporeSmeBanner} id="demo-section">
        <Container>
          <Grid alignItems="flex-start" container justify="center" mt={3}>
            <Grid item md={7} sm={12}>
              <Box>
                <HeaderTypography
                  fontSize={{ sm: 36, xs: 34 }[width] || 42}
                  fontWeight={600}
                  lineHeight={1.25}
                  mb={4}
                  mt={3}
                  overrideClassName
                  component="h1"
                >
                  Drive Sales with{" "}
                  <span style={{ color: "#15a323" }}>WhatsApp CRM</span>
                </HeaderTypography>
                <ParagraphTypography
                  className={classes.smeBannerTxt}
                  color="#2e3f4f"
                  component="h2"
                  font-family="SourceSansPro"
                  fontSize={23}
                  mb={3}
                  mt={0}
                >
                  Leverage Singapore's #1 WhatsApp CRM to seamlessly engage with
                  your customers.
                </ParagraphTypography>
                <Box pr={{ sm: "0px", xs: "0px" }[width] || "3rem"}>
                  <Grid
                    alignItems="center"
                    container
                    justifyContent="start"
                    sm={12}
                    xs={12}
                  >
                    <Grid item md={6} sm={12}>
                      <Box>
                        <List className={classes.nopadtpbtm} pl={0}>
                          {listOne.map((each) => (
                            <ListItem
                              alignItems="center"
                              className={classes.nopad}
                              key={each}
                            >
                              <ListItemIcon>
                                <CheckIcon className={classes.listItemIcon} />
                              </ListItemIcon>
                              <ListItemText className={classes.listItemTextes}>
                                {each}
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Box>
                        <List className={classes.nopadtpbtm} pl={0}>
                          {listTwo.map((each) => (
                            <ListItem
                              alignItems="center"
                              className={classes.nopad}
                              key={each}
                            >
                              <ListItemIcon>
                                <CheckIcon className={classes.listItemIcon} />
                              </ListItemIcon>
                              <ListItemText className={classes.listItemTextes}>
                                {each}
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <br />
                <Box
                  display={{ sm: "block", xs: "block" }[width] || "flex"}
                  className={classes.bannerImageClass}
                >
                  <Box
                    alt="WhatsApp CRM"
                    component={"img"}
                    maxHeight="450px"
                    maxWidth="100%"
                    src={singaporeSmeTestimonialImg}
                  />
                </Box>
              </Box>

              <Hidden mdUp>
                <Box textAlign="center" classNmae={classes.hiddencomp}>
                  <Container
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      padding: "3rem 0",
                      paddingTop: 0,
                      marginTop: "-53px",
                      position: "relative",
                      zIndex: "1000",
                    }}
                  >
                    <Box
                      maxWidth={1015}
                      className={classes.wpHeader}
                      textAlign="center"
                    >
                      <Box
                        component={"img"}
                        src={IMDA_SME_Large}
                        alt={"IMDA Pre-approved Solution Provider"}
                        maxWidth={"100%"}
                        margin={0}
                        width={"100%"}
                        mb={0}
                        className={classes.smeBannerImg}
                        onClick={() =>
                          window.open(
                            "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
                          )
                        }
                        sx={{ cursor: "pointer" }}
                      />
                      <Box className={classes.SmeStyling}>
                        <ParagraphTypography
                          color="text.secondary"
                          fontSize={"1em"}
                          sx={{ padding: 0, margin: 0 }}
                          textAlign="center"
                        >
                          SMEs are eligible for up to 50% Productivity Solutions
                          Grant (PSG) support for the adoption of Pepper Cloud
                          CRM solution, a Pre-Approved Solution under the IMDA
                          SMEs Go Digital programme.
                        </ParagraphTypography>
                      </Box>
                    </Box>
                  </Container>
                  <Container>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.overlapBackgrounds}
                    >
                      <Box className={classes.overlapBox}>
                        {overlapImageList.map((each) => (
                          <Box
                            alt={each.alt}
                            component={"img"}
                            maxHeight="450px"
                            maxWidth="100%"
                            src={each.logo}
                            className={classes.overlapImage}
                          />
                        ))}
                      </Box>
                    </Grid>
                  </Container>
                </Box>
              </Hidden>
            </Grid>

            <Grid item md={5} sm={9} xs={12} id="#demo">
              <Box
                className={classes.singaporeSmeFromBox}
                px={1}
                textAlign="center"
              >
                <Box className={classes.singaporeSmeFrom} ref={myRef}>
                  <HeaderTypography
                    className={classes.singaporeSmeFromHeader}
                    color="#fff"
                    component="h3"
                    fontWeight={600}
                    m={0}
                    textAlign="center"
                  >
                    Avail up to <b className={classes.colorChange}>50%</b> PSG
                    grant
                  </HeaderTypography>
                  <Box className={classes.webformContainer}>
                    <Box
                      className={classes.pcWebForm}
                      border="none"
                      component="iframe"
                      id="pc-iframe"
                      overflow="hidden"
                      referrerpolicy="unsafe-url"
                      src="https://app.peppercloud.com/form/58bafeec-a7dd-4c6e-97c9-88d290c12618/embed"
                      width="99%"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Hidden smDown>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3rem 0",
            paddingTop: 0,
            marginTop: "-53px",
            position: "relative",
            zIndex: "1000",
          }}
        >
          <Box maxWidth={1015} className={classes.wpHeader} textAlign="center">
            <Box
              component={"img"}
              src={IMDA_SME_Large}
              alt={"IMDA Pre-approved Solution Provider"}
              maxWidth={"100%"}
              margin={0}
              width={"100%"}
              mb={0}
              style={{ backgroundColor: "white", borderRadius: "12px" }}
              onClick={() =>
                window.open(
                  "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
                )
              }
              sx={{ cursor: "pointer" }}
            />
            <Box className={classes.SmeStyling}>
              <ParagraphTypography
                color="text.secondary"
                fontSize={"1em"}
                sx={{ padding: 0, margin: 0 }}
                textAlign="center"
              >
                SMEs are eligible for up to 50% Productivity Solutions Grant
                (PSG) support for the adoption of Pepper Cloud CRM solution, a
                Pre-Approved Solution under the IMDA SMEs Go Digital programme.
              </ParagraphTypography>
            </Box>
          </Box>
        </Container>

        <Box textAlign="center" maxWidth={1015} m={"auto"} p={0}>
          <Container style={{ padding: 0 }}>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classes.overlapBackground}
            >
              <Box className={classes.overlapBox}>
                {overlapImageList.map((each) => (
                  <Box
                    alt={each.alt}
                    component={"img"}
                    maxHeight="450px"
                    maxWidth="100%"
                    src={each.logo}
                    className={classes.overlapImage}
                  />
                ))}
              </Box>
            </Grid>
          </Container>
        </Box>
      </Hidden>
      {/* Content*/}

      {/* image */}
      <br />
      <br />
      <Box className={classes.smartCrmToolPadding}>
        <Box component={Container} textAlign="center">
          <HeaderTypography
            component="h2"
            fontSize={40}
            fontWeight={600}
            className={classes.headingwidth}
            // maxWidth="800px"
          >
            Capture, engage, and convert leads <br />
            with <span style={{ color: "#15a323" }}>WhatsApp CRM</span> software
          </HeaderTypography>
          <Box textAlign="center" className={classes.toolPadding}>
            <Box display="flex" justifyContent="center">
              <Grid alignItems="center" container justify="center" spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.listMargin}>
                    <List className={classes.nopadtpbtm} pl={0}>
                      {whatsappCrmList.map((each) => (
                        <ListItem
                          alignItems="center"
                          className={classes.nopads}
                          key={each.name}
                        >
                          <ListItemAvatar height="56px" width="56px">
                            <img
                              alt={each.alt}
                              src={each.logo}
                              style={{ width: "56px", height: "56px" }}
                            />
                          </ListItemAvatar>
                          <ListItemText style={{ marginLeft: "30px" }}>
                            <Box className={classes.primaryText}>
                              {each.title}
                            </Box>
                            <Box className={classes.secondaryText}>
                              {each.name}
                            </Box>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Box
                    display="flex"
                    justifyContent={
                      { sm: "center", xs: "center" }[width] || "flex-end"
                    }
                  >
                    <StaticImage
                      alt="WhatsApp Catalog"
                      className={classes.cycleProcessImg}
                      placeholder="blurred"
                      src="../images/v2/whatsapp/First Image.png"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <br />
      <br />
      {/* Content */}
      <Box textAlign="center" className={classes.pepperCloudCRMHelpsContent}>
        <HeaderTypography
          component="h2"
          fontWeight={600}
          style={{ padding: "0 10px" }}
          className={classes.headingText}
        >
          Power up your business using{" "}
          <span style={{ color: "#15a323" }}>
            {" "}
            CRM with WhatsApp integration
          </span>
        </HeaderTypography>
        <Box
          py={{ sm: 4, xs: 3 }}
          className={classes.pepperCloudCRMHelpsContent}
        >
          <Container>
            <Box className={classes.pepperCloudCRMHelpsContentListBox}>
              <ul className={classes.pepperCloudCRMHelpsContentList}>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        90%+
                      </div>
                      <div>Open rate</div>
                    </div>
                    <div className={classes.yellowImageLine}>
                      <img
                        src={yellowLine}
                        style={{ marginLeft: "45px" }}
                        alt="vertical line"
                      />
                    </div>
                  </Box>
                </li>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        70%+
                      </div>
                      <div>Deal closing rate</div>
                    </div>
                    <div className={classes.yellowImageLine}>
                      <img
                        src={yellowLine}
                        style={{ marginLeft: "45px" }}
                        alt="vertical line"
                      />
                    </div>
                  </Box>
                </li>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        60%
                      </div>
                      <div style={{ width: "100%" }}>
                        More revenue generation
                      </div>
                    </div>
                    <div className={classes.yellowImageLine}>
                      <img
                        src={yellowLine}
                        style={{ marginLeft: "45px" }}
                        alt="vertical line"
                      />
                    </div>
                  </Box>
                </li>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        20%
                      </div>
                      <div>More conversation rate</div>
                    </div>
                  </Box>
                </li>
              </ul>
            </Box>
            <Box textAlign="center" className={classes.demobtn}>
              <Button
                className="book-demo-btn"
                color="secondary"
                size="large"
                variant="contained"
                onClick={executeScroll}
                component="h4"
              >
                Book a Free Demo
              </Button>
            </Box>
            <Box className={classes.whatsappDisclaimer}>
              *WhatsApp statistics from multiple sources
            </Box>
          </Container>
        </Box>
      </Box>

      <br />
      <br />
      {/* whatsapp card section */}
      <Container>
        <div style={{ position: "relative" }}>
          <HeaderTypography
            className={classes.pepperCloudCRMHelpsText}
            fontWeight={600}
            my={4}
            textAlign="center"
          >
            <span style={{ color: "#15a323" }}>
              WhatsApp CRM{" "}
              <img
                src={whatsappTitleImage}
                alt="WhatsApp"
                className={classes.whatsappTitleImageContainer}
              />
            </span>
            <br />
            <span>Built to support all industries</span>
          </HeaderTypography>
        </div>

        <Grid
          alignItems="center"
          container
          spacing={5}
          className={classes.containerImage}
        >
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box className={classes.relativeContainerSide}>
              <Card
                className={`${classes.cardindexBackground1}`}
                style={{ width: "100%" }}
              >
                <CardContent>
                  <HeaderTypography
                    component="h3"
                    fontSize={22}
                    fontWeight={600}
                    mt={0}
                  >
                    Manufacturing
                  </HeaderTypography>

                  <ParagraphTypography
                    component="h3"
                    fontSize={18}
                    mt={2}
                    textAlign={{ sm: "center", xs: "center" }[width] || "left"}
                    className={classes.textImage}
                  >
                    WhatsApp CRM, best CRM for manufacturing businesses, can be
                    used to reply to product queries, provide order updates, and
                    do more.
                  </ParagraphTypography>
                </CardContent>
                <CardMedia
                  alt="WhatsApp CRM for Manufacturing Business"
                  component="img"
                  className={classes.cardImg}
                  image={card1Img}
                />
              </Card>
              <Box
                src={whatsapptextImg1}
                component={"img"}
                width="255px"
                className={classes.whatsapptextImg1}
                alt="WhatsApp CRM for Manufacturing Business"
              />
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box className={classes.relativeContainerSide}>
              <Card
                className={`${classes.cardindexBackground2}`}
                style={{ width: "100%" }}
              >
                <CardContent>
                  <HeaderTypography
                    component="h3"
                    fontSize={22}
                    fontWeight={600}
                    mt={0}
                  >
                    Healthcare
                  </HeaderTypography>

                  <ParagraphTypography
                    component="h3"
                    fontSize={18}
                    mt={2}
                    textAlign={{ sm: "center", xs: "center" }[width] || "left"}
                    className={classes.textImage}
                  >
                    WhatsApp CRM for healthcare companies assists in automating
                    appointment booking and reminders, sharing lab results, etc.
                  </ParagraphTypography>
                </CardContent>
                <CardMedia
                  alt="WhatsApp CRM for Health Care Marketing"
                  component="img"
                  className={classes.cardImg}
                  image={card2Img}
                />
              </Card>
              <Box
                src={whatsapptextImg2}
                component={"img"}
                width="255px"
                className={classes.whatsapptextImg1}
                alt="WhatsApp CRM for Health Care Marketing"
              />
            </Box>
          </Grid>

          <Grid
            item
            md={4}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box className={classes.relativeContainerSide}>
              <Card
                className={`${classes.cardindexBackground3}`}
                style={{
                  width: "100%",
                }}
              >
                <CardContent>
                  <HeaderTypography
                    component="h3"
                    fontSize={22}
                    fontWeight={600}
                    mt={0}
                  >
                    Food delivery
                  </HeaderTypography>

                  <ParagraphTypography
                    component="h3"
                    fontSize={18}
                    mt={2}
                    textAlign={{ sm: "center", xs: "center" }[width] || "left"}
                    className={classes.textImage}
                  >
                    Food delivery companies automate order confirmations,
                    provide real-time delivery updates, send offers, and more.
                  </ParagraphTypography>
                </CardContent>
                <CardMedia
                  alt="WhatsApp CRM for Food Delivery"
                  component="img"
                  className={classes.cardImg}
                  image={card3Img}
                />
              </Card>
              <Box
                src={whatsapptextImg3}
                component={"img"}
                width="255px"
                className={classes.whatsapptextImg1}
                alt="WhatsApp CRM for Food Delivery"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <br />
      <br />
      <br />
      <br />
      <Box className={classes.smartCrmTool}>
        <Box component={Container} className={classes.listTopPadding}>
          <Box display="flex" justifyContent="center">
            <Grid
              alignItems="center"
              container
              justify="center"
              spacing={2}
              className={classes.listColumn}
            >
              <Grid item md={6} sm={12} xs={12}>
                <Box
                  display="flex"
                  justifyContent={
                    { sm: "center", xs: "center" }[width] || "flex-end"
                  }
                  className={classes.listBackground}
                >
                  <StaticImage
                    alt="Omnichannel CRM System"
                    className={classes.cycleProcessImg}
                    placeholder="blurred"
                    src="../images/v2/whatsapp/Second Image.png"
                  />
                </Box>
              </Grid>
              <Grid item alignItems="center" md={6} sm={12} xs={12}>
                <Box textAlignLast="start" className={classes.whyText}>
                  <HeaderTypography
                    className={classes.pepperCloudCRMHelpsText}
                    fontSize={40}
                    fontWeight={600}
                    my={4}
                    textAlign="center"
                  >
                    Why{" "}
                    <span style={{ color: "#15a323" }}>Pepper Cloud CRM?</span>
                  </HeaderTypography>
                  <ParagraphTypography
                    className={classes.smeBannerTxts}
                    color="#2e3f4f"
                    component="h2"
                    font-family="SourceSansPro"
                    fontSize={20}
                    mb={3}
                    mt={0}
                    lineHeight="36px"
                  >
                    Singapore’s best all-in-one sales CRM software is designed
                    for small and medium-sized businesses for effective sales
                    management.
                  </ParagraphTypography>
                  <Box className={classes.listPadding}>
                    <Grid
                      alignItems="center"
                      container
                      justifyContent="start"
                      sm={12}
                      xs={12}
                    >
                      <Grid item md={6} sm={12}>
                        <Box>
                          <List className={classes.nopadtpbtm} pl={0}>
                            {listThree.map((each) => (
                              <ListItem
                                alignItems="center"
                                className={classes.nopad}
                                key={each}
                              >
                                <ListItemIcon>
                                  <CheckIcon className={classes.listItemIcon} />
                                </ListItemIcon>
                                <ListItemText className={classes.listItemTexts}>
                                  {each}
                                </ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        className={classes.listFourpadding}
                      >
                        <Box>
                          <List className={classes.nopadtpbtm} pl={0}>
                            {listFour.map((each) => (
                              <ListItem
                                alignItems="center"
                                className={classes.nopad}
                                key={each}
                              >
                                <ListItemIcon>
                                  <CheckIcon className={classes.listItemIcon} />
                                </ListItemIcon>
                                <ListItemText className={classes.listItemTexts}>
                                  {each}
                                </ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* clients Sections */}

      <Box>
        <Container>
          <HeaderTypography
            className={classes.pepperCloudCRMHelps}
            fontWeight={600}
            my={4}
            textAlign="center"
          >
            <span fontWeight={600}>
              Best CRM tool with{" "}
              <span
                style={{ color: "#15a323" }}
                className={classes.integration}
              >
                WhatsApp integration
              </span>{" "}
              <br /> trusted by many
            </span>
          </HeaderTypography>
          <CompanyCarousel className={classes.cimg} />
        </Container>
      </Box>

      <Box mb={8} className={classes.faqbackground}>
        <Box mt={1} mb={0}>
          <Divider light />
        </Box>
        <Container>
          <section>
            <HeaderTypography
              className={classes.pepperCloudCRMHelps}
              fontSize={40}
              fontWeight={600}
              my={4}
              textAlign="center"
            >
              Frequently asked questions
            </HeaderTypography>
            <Card className={classes.faqCard}>
              <Box padding={3}>
                {salesCRMSolution.map((each, index) => (
                  <div key={each.title}>
                    <Box my={2}>
                      <Box
                        alignItems="center"
                        component="h3"
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() =>
                          setActiveCalendar(
                            index === activeCalendar ? -1 : index
                          )
                        }
                      >
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {index !== activeCalendar ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                        <Box
                          component="h3"
                          mt={0}
                          fontSize={18}
                          fontWeight="600"
                          m={0}
                          ml={3}
                        >
                          {each.title}
                        </Box>
                      </Box>
                      <Collapse in={activeCalendar === index}>
                        <ParagraphTypography
                          color="#2e3f4f"
                          fontSize={18}
                          mr={6}
                          mt={2}
                          ml={8.5}
                        >
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Divider color="#707070" />
                  </div>
                ))}
              </Box>
            </Card>
          </section>
        </Container>
      </Box>

      {/* Book a free demo */}

      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Container>
          <Box alignItems="center" display="flex" justifyContent="center">
            <HeaderTypography
              color="common.white"
              fontSize={40}
              fontWeight={600}
              my={0}
              textAlign="center"
              component="h2"
              className={classes.boostText}
            >
              Boost your sales with WhatsApp CRM solution
            </HeaderTypography>
          </Box>
          <Grid item md={10} sm={12}>
            <Box className={classes.boostTextwidth}>
              <List className={classes.nopadding} pl={0}>
                {listFive.map((each) => (
                  <ListItem
                    alignItems="center"
                    className={classes.nopad}
                    key={each}
                  >
                    <ListItemIcon className={classes.tickImages}>
                      <Box alt="Check icon" component={"img"} src={tickImage} />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemTextWhite}>
                      {each}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Box mt={6} textAlign="center">
            <WhiteButton
              color="primary"
              mb={6}
              onClick={executeScroll}
              size="large"
              variant="contained"
            >
              Book a Free Demo
            </WhiteButton>
          </Box>
        </Container>
      </Box>

      {/* footer */}
      <Box py={5} className={classes.footerPadding}>
        <Container>
          <Box textAlign="center">
            <Link to="/">
              <Box
                alt="Pepper Cloud - Best WhatsApp CRM Software"
                component={"img"}
                height="100px"
                src={Logo}
              />
            </Link>
            <ParagraphTypography
              color="#2e3f4f"
              fontFamily="Poppins"
              fontSize={{ sm: 14, xs: 14 }[width] || 20}
              m4={0}
            >
              #34-04, Tower 1, One Raffles Place, Singapore 048616
            </ParagraphTypography>
          </Box>
        </Container>
        <Box
          color="#cbcccd"
          textAlign="end"
          paddingRight="23px"
          className={classes.copyFooter}
        >
          © Pepper Cloud - Singapore's Best WhatsApp CRM Software 2023
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default whatsapp;
